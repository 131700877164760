jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});
	
	$('.bs-select').each(function() {
		var $this = $(this);
		$this.selectpicker($.extend({
			noneSelectedText: '(válasszon)',
			liveSearch: true,
			liveSearchPlaceholder: '(keresés)'
		}, $this.data()));
	});
	
	init_tooltip();
	
	$('.fancybox').fancybox();

	var $form_signin = $(".form-signin");
	if($form_signin.length){
		$signin_back = $(".signin-back", $form_signin);
		$signin_email = $(".signin-email", $form_signin);
		$signin_password = $(".signin-password", $form_signin);
		$form_groups = $(".form-groups", $form_signin);

		$(".signin-btn-tovabb").on("click", function(e){
			e.preventDefault();

			if(!$signin_email.val()) return;

			$form_groups.css("transform", "translateX(-50%)");
			$signin_back.addClass("show");

			setTimeout(function() {$signin_password.focus();}, 150);
		});

		$signin_email.on("keydown", function(e){
			if (e.which === 9 || e.keyCode === 9 || e.which === 13 || e.keyCode === 13) {
				e.preventDefault();
				$(".signin-btn-tovabb").click();
			};
		});

		$signin_password.on("keydown", function(e){
			if (e.shiftKey === true && (e.which === 9 || e.keyCode === 9)) {
				e.preventDefault();
				$signin_back.click();
			};
		});

		$signin_back.on("click", function(){
			if(!$(this).hasClass("show")) return;

			$form_groups.css("transform", "translateX(0)");
			$(this).removeClass("show");

			setTimeout(function() {$signin_email.focus();}, 150);
		});
	}


	allo_fekvo();

	Dropzone.options.mydropzone = {

		autoProcessQueue: false,
		uploadMultiple: true,
		parallelUploads: 100,
		maxFiles: 100,
		addRemoveLinks: true,
		previewsContainer: '.dropzone-previews',
		hiddenInputContainer: '.dropzone-hidden',
		dictDefaultMessage: 'Kattintson vagy húzza ide a feltölteni kívánt képeket',
		dictRemoveFile: 'Mégse',
		acceptedFiles: 'image/*',
		clickable: '.dz-message',
		
		init: function () {
			var myDropzone = this,
				$this = $(this.element),
				preview = false;

			$this.on('submit', function (e) {
				preview = $this.find('#submit_value').val() == 'preview';

				if (myDropzone.getQueuedFiles().length && !preview) {
					e.preventDefault();
					e.stopPropagation();
					myDropzone.processQueue();
				}
			});

			this.on('sendingmultiple', function () {
				// Gets triggered when the form is actually being sent.
				// Hide the success button or the complete form.
			});
			this.on('successmultiple', function (files, resp) {
				// Gets triggered when the files have successfully been sent.
				// Redirect user or notify of success.
				myDropzone.removeAllFiles();
				cbs.callbackHandler($this.data('callback'), resp, $this);
			});
			this.on('errormultiple', function (files, resp) {
				// Gets triggered when there was an error sending the files.
				// Maybe show form again, and notify user of error
			});
		}

	}
	
	// ----- textarea#uzenet -----
	autosize($('.autosize'));
	
	init_textcomplete();
	
	$('.slide').each(function() {
		var $this = $(this),
			$target = $($this.data('slide-target'));
		
		$target.slideUp();
		
		$this.on('focus', function () {
			$target.slideDown();
//		}).on('blur', function () {
//			if ($this.val() == '') {
//				$target.slideUp();
//			}
		});
	});
	
	// ----- -----
	
	init_linkify();
	
	$('.infinitescroll').each(function () {
		var $this = $(this),
			$window = $(window),
			$document = $(document),
			$loading = $($this.data('loading')),
			gap = $this.data('gap') || 150;

		setInterval(function () {
			var $next = $('a.next');
			if ($next.length && $document.height() - $window.height() - gap <= $window.scrollTop()) {
				$next.remove();
				$loading.show();

				$.ajax({
					url: $next.attr('href'),
					method: 'GET',
					dataType: 'html',
					success: function (html) {
						$this.append(html);
						$loading.hide();
						init_linkify();
						init_textcomplete();
						init_tooltip();
						autosize($('.autosize'));
						allo_fekvo();
					}
				});
			}
		}, 200);
	});
	
	$('.naptar').each(function () {
		var $this = $(this),
			$modal = $($this.data('modal'));
			
		var $closeButton = $this.siblings('.torol');
			
		$this.fullCalendar({
			allDaySlot: false,
			businessHours: {
				dow: [1, 2, 3, 4, 5],
				start: '08:00',
				end: '17:30',
			},
			defaultView: 'agendaWeek',
			editable: false,
			eventOverlap: false,
			eventClick: function (calEvent, e) {
				if (calEvent.remote && ! $(e.target).is('.glyphicon-trash')) {
					$modal.modal({
						remote: calEvent.remote
					}).modal('show');
				}
				return false;
			},
			eventDrop: function(calEvent, delta, revertFunc) {
				var params = {
					start: calEvent.start.format('YYYY-MM-DD HH:mm'),
					end: calEvent.end.format('YYYY-MM-DD HH:mm')
				};
				$modal.modal({
					remote: calEvent.remote + '?' + jQuery.param(params)
				}).modal('show');
			},
			eventRender: function (event, element) {
				if (event.editable && event.delete) {
					element.find('.fc-content').prepend($closeButton.clone(true).removeClass('hidden').attr('href', event.delete));
				}
			},
			eventResize: function(calEvent, delta, revertFunc) {
				var params = {
					start: calEvent.start.format('YYYY-MM-DD HH:mm'),
					end: calEvent.end.format('YYYY-MM-DD HH:mm')
				};
				$modal.modal({
					remote: calEvent.remote + '?' + jQuery.param(params)
				}).modal('show');
			},
			events: {
				url: $this.data('url'),
				type: 'GET',
				data: function () {
					return {
						type: $this.data('type')
					};
				}
			},
			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay,listWeek'
			},
			locale: 'hu',
			minTime: '07:00',
			maxTime: '20:00',
			navLinks: true,
			select: function (start, end) {
				var params = {
					start: start.format('YYYY-MM-DD HH:mm'),
					end: end.format('YYYY-MM-DD HH:mm'),
					type: $this.data('type')
				};
				$modal.modal({
					remote: $this.data('create-url') + '?' + jQuery.param(params)
				}).modal('show');
			},
			selectable: true,
			selectAllow: function (selectInfo) {
				return selectInfo.start.isSame(selectInfo.end, 'day');
			},
			selectOverlap: false,
			timezone: 'Europe/Budapest'
		});
	});
	
	$('.tipusok .badge').each(function() {
		var $this = $(this);
		$this.toggle($this.html() > 0);
	});

});

$.fn.selectRange = function (start, end) {
	if (end === undefined) {
		end = start;
	}
	return this.each(function () {
		if ('selectionStart' in this) {
			this.selectionStart = start;
			this.selectionEnd = end;
		} else if (this.setSelectionRange) {
			this.setSelectionRange(start, end);
		} else if (this.createTextRange) {
			var range = this.createTextRange();
			range.collapse(true);
			range.moveEnd('character', end);
			range.moveStart('character', start);
			range.select();
		}
	});
};

$(document).on('mousewheel' ,'.scroll-left', function(e, delta) {
	e.preventDefault();
	var $this = $(this),
		pixel = $this.data('pixel') || 50;
	$this.scrollLeft($this.scrollLeft() - (delta * pixel));
});

$(document).on('keypress', '.submit-on-enter', function(e) {
	if ((e.keyCode == 13 || e.which == 13) && e.shiftKey == false) {
		e.preventDefault();
		$(this).parents('form').submit();
	}
});

$(document).on('click', '.insert-text', function(e) {
	e.preventDefault();
	
	var $this = $(this),
		$target = $($this.attr('href') || $this.data('target')),
		textToAdd = $this.data('text') || $this.text(),
		val = $target.val(),
		caretPos = $target[0].selectionStart;
		
	$target
		.val(val.substring(0, caretPos) + textToAdd + val.substring(caretPos))
		.selectRange(caretPos + textToAdd.length)
		.trigger('focus');
});

$(document).popover({
	container: 'body',
	html: true,
	content: '<div>asd</div><div>hello</div>',
	placement: 'auto top',
	trigger: 'hover',
	delay: {
		show: 300,
		hide: 800
	},
	selector: '[rel="popover"]'
});

$(document).on('shown.bs.collapse', '#giphy', function () {
	$('[href="#giphy"]').addClass('dropup');
	$(this).find('input.giphies').trigger('input');
});

$(document).on('hidden.bs.collapse', '#giphy', function () {
	$('[href="#giphy"]').removeClass('dropup');
});

$(document).on('hidden.bs.modal', '.modal', function () {
	$(this).removeData('bs.modal');
//	$($(this).data('naptar')).fullCalendar('refetchEvents');
});

$(document).on('shown.bs.modal', '#telefonkonyv_modal', function () {
	var $this = $(this);
	if ($this.find('form').length) {
		$this.find('[required]').each(function() {
			$(this).parents('.form-group').find('label').first().append('&nbsp;<span class="text-danger" title="Kötelező mező">*</span>');
		});
	}
});

$(document).on('click', '.submit-value', function() {
	var $this = $(this),
		$target = $($this.data('target'));
	$target.val($this.val());
});

$(document).on('shown.bs.modal', '#naptar_modal, #szakmaiverseny_modal', function () {
	var $this = $(this);
	if ($this.find('form').length) {
		$this.find('[required]').each(function() {
			$(this).parents('.form-group').find('label').first().append('&nbsp;<span class="text-danger" title="Kötelező mező">*</span>');
		});
		var $end = $('#end'),
			$start = $('#start');
		
		$this.find('.datetimepicker').datetimepicker({
			language: 'hu',
			weekStart: 1,
			autoclose: 1,
			todayHighlight: 1,
			startView: 1,
			minView: 0,
			maxView: 0,
			minuteStep: 15,
			format: 'yyyy-mm-dd hh:ii',
			hoursDisabled: [0, 1, 2, 3, 4, 5, 6, 21, 22, 23],
			onRenderHour: function (date) {
				if (date.getUTCHours() < 7 || date.getUTCHours() > 20) {
					return ['disabled'];
				}
			},
			onRenderMinute: function (date) {
				if (date.getUTCHours() < 7 || date.getUTCHours() > 20) {
					return ['disabled'];
				}
			}
		}).on('changeDate', function () {
			var $this = $(this);
			if ($this.is('#start')) {
				$end.datetimepicker('setStartDate', $this.val());
				$end.datetimepicker('setEndDate', moment($this.val()).hour(20).minute(0).format('YYYY-MM-DD HH:mm'));
			} else {
				$start.datetimepicker('setEndDate', $this.val());
				$start.datetimepicker('setStartDate', moment($this.val()).hour(7).minute(0).format('YYYY-MM-DD HH:mm'));
			}
		});
		$end.datetimepicker('setStartDate', $start.val());
		$end.datetimepicker('setEndDate', moment($start.val()).hour(20).minute(0).format('YYYY-MM-DD HH:mm'));
		
		$start.datetimepicker('setEndDate', $end.val());
		$start.datetimepicker('setStartDate', moment($end.val()).hour(7).minute(0).format('YYYY-MM-DD HH:mm'));
	}
});

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('input', 'form.auto.input :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

// törlés gomb
$(document).on('click', '.torol', function(e) {
	e.preventDefault();
	var $this = $(this);
	if (confirm($this.data('confirm') || 'Biztos benne?')) {
		$.ajax({
			url: $this.attr('href'),
			method: 'DELETE',
			success: function(resp) {
				cbs.callbackHandler($this.data('callback'), resp, $this);
			}
		});
	}
});

$('form#mydropzone').on('reset', function() {
	var $this = $(this);
	setTimeout(function() {
		autosize.update($('.autosize'));
		$('.textoverlay-wrapper .textoverlay span').remove();
		$this.find('.bs-select').selectpicker('val', '');
		allo_fekvo();
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	uzenet_deleted: function (resp, $elem) {
		if (resp.status == 'success') {
			$('#uzenet' + resp.id).slideUp(400, function() {
				$(this).remove();
			});
			tipusok_count(resp.count);
		}
	},
	uzenet_created: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			if ( ! resp.preview) {
				$elem.resetForm();
			}
			$('.uzenet.preview').slideUp(400, function() {
				$(this).remove();
			});
			
			var $html = $(resp.html).css('display', 'none');
			init_linkify($html);
			$($elem.data('target')).prepend($html);
			$html.slideDown();
			init_textcomplete();
			init_tooltip();
			
			tipusok_count(resp.count);
			
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value.join('<br>') : value;
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value + '</span>');
			});
		}
	},
	uzenet_szavazat: function (resp, $elem) {
		if (resp.status == 'success') {
		}
		$.each(resp.count || {}, function(i, e) {
			var $this = $('#szavaz' + i);
			$.each(e || {}, function(key, value) {
				var $elem = $this.find('.' + key),
					$fa = $elem.find('.fa'),
					icons = ($fa.data('icons') || '').split('|');
					
				$.each(icons || [], function(i, e) {
					$fa.removeClass(e);
				});
				$fa.addClass(icons[value.szavazott]);
				$elem.find('small').html(value.count);
				$elem.find('[data-toggle="tooltip"]').tooltip('hide').attr('title', value.title).tooltip('fixTitle')
					.data('original-title', value.title).attr('data-original-title', value.title);
			});
		});
	},
	komment_deleted: function (resp, $elem) {
		if (resp.status == 'success') {
			$('#komment' + resp.id).slideUp(400, function() {
				$(this).remove();
			});
			komment_count(resp.count);
		}
	},
	komment_created: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			$elem.resetForm();
			
			var $html = $(resp.html).css('display', 'none');
			init_linkify($html);
			$($elem.data('target')).prepend($html);
			$html.slideDown();
			
			autosize.update($('.autosize'));
			$('.textoverlay-wrapper .textoverlay span').remove();

			komment_count(resp.count);
			
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value.join('<br>') : value;
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value + '</span>');
			});
		}
	},
	naptar_esemeny_delete: function (resp, $elem) {
		if (resp.status == 'success') {
			var $modal = $elem.parents('.modal');
			if ($modal.length) {
				$modal.modal('hide');
				$($modal.data('naptar')).fullCalendar('refetchEvents');
			} else {
				$($elem.parents('.fc')).fullCalendar('refetchEvents');
			}
		}
	},
	naptar_esemeny_saved: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			var $modal = $elem.parents('.modal');
				$modal.modal('hide');
			$($modal.data('naptar')).fullCalendar('refetchEvents');
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value.join('<br>') : value;
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value + '</span>');
			});
		}
	},
	/* === Telefonkönvy === */
	telefonkonyv_delete: function (resp, $elem) {
		document.location.reload();
	},
	telefonkonyv_saved: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			document.location.reload();
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value.join('<br>') : value;
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value + '</span>');
			});
		}
	},

	/*=== Szakmai verseny === */
	szakmaiverseny_delete: function (resp, $elem) {
		document.location.reload();
	},
	szakmaiverseny_saved: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			document.location.reload();
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value.join('<br>') : value;
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value + '</span>');
			});
		}
	},
	csillagoz: function(resp, $elem) {
		if (resp.attached) {
			$elem.parents(".uzenet").addClass("csillagozott");
			$elem.find(".fa").removeClass("fa-star-o").addClass("fa-star");
		} else {
			$elem.parents(".uzenet").removeClass("csillagozott");
			$elem.find(".fa").removeClass("fa-star").addClass("fa-star-o");
		}
		tipusok_count(resp.count);
	},
	giphy_search: function(resp, $elem) {
		$($elem.data('target')).html(resp);
	}
};

function allo_fekvo() {
	$('img.allo-fekvo').each(function() {
		var $this = $(this),
			img = new Image();
		img.onload = function() {
			$this.removeClass('allo-fekvo').addClass(img.width > img.height ? 'fekvo' : 'allo');
		}
		img.src = $this.attr('src');
	});
}

function tipusok_count(count) {
	var $tipusok = $('.tipusok');
	$.each(count || {}, function(key, value) {
		$tipusok.find('.' + key).html(value).toggle(value > 0);
	});
}

function komment_count(count) {
	$.each(count || {}, function(i, e) {
		$('[href="#kommentek_collapse' + i + '"]').each(function() {
			var $this = $(this),
				text = ($this.data('text') || '').split('|'),
				index = (text.length > 1 && e > 0) ? 1 : 0;

			$this.html(text[index].split('#n').join(e));
		});
	});
}

function init_linkify(selector) {
	var $target = $(selector || 'body').find('.linkify:not(.linkified)');
	
	$target.linkify({
		target: {
			url: '_blank'
		}
	});
	
	$target.addClass('linkified');
}

function init_tooltip() {
	$('[data-toggle="tooltip"]').tooltip({
		container: 'body'
	});
}

var textcomplete_urls;
function init_textcomplete() {
	var limit = 20;
	if ( ! textcomplete_urls) {
		textcomplete_urls = $('#uzenet').data('urls');
	}
	$('.textcomplete').textcomplete([
		{
			id: 'event',
			match: /(^|\s)@evt-([\wáéíóöőúüű-]*)$/i,
			search: function (term, callback) {
				$.getJSON(textcomplete_urls.event, {
					q: term,
					limit: limit
				}).done(function (resp) {
					callback(resp);
				}).fail(function () {
					callback([]);
				});
			},
			replace: function (value) {
				return '$1@evt-' + value.id + ' ';
			},
			idProperty: 'id',
			template: function(value) {
				return '<strong>' + value.nev + '</strong> <small>' + value.tipus + ', ' + value.start + ' - ' + value.end + '</small>';
			}
		},
		{
			id: 'mention',
			match: /(^|\s)@([\wáéíóöőúüű-]*)$/i,
			search: function (term, callback) {
				$.getJSON(textcomplete_urls.user, {
					q: term,
					limit: limit
				}).done(function (resp) {
					callback(resp);
				}).fail(function () {
					callback([]);
				});
			},
			replace: function (user) {
				return '$1@' + user.username + ' ';
			},
			idProperty: 'username',
			template: function(user) {
				return '<img src="' + user.gravatar + '"> <strong>' + user.nev + '</strong> <small>' + user.email + '</small>';
			}
		},
		{
			id: 'hashtag',
			match: /(^|\s)#([\wáéíóöőúüű-]*)$/i,
			search: function (term, callback) {
				$.getJSON(textcomplete_urls.hashtag, {
					q: term,
					limit: limit
				}).done(function (resp) {
					callback(resp);
				}).fail(function () {
					callback([]);
				});
			},
			replace: function (value) {
				return '$1#' + value + ' ';
			}
		}
	], {
		maxCount: limit,
		appendTo: 'body'
	}).overlay([
		{
			id: 'mention',
			match: /(^|\s)@user-\d+[\w-]*/gi,
			css: {
				'background-color': '#d8dfea'
			}
		},
		{
			id: 'event',
			match: /(^|\s)@evt-\d+/gi,
			css: {
				'background-color': '#d8dfea'
			}
		},
		{
			id: 'hashtag',
			match: /(^|\s)#[\wáéíóöőúüű-]+/gi,
			css: {
				'background-color': '#d8dfea'
			}
		}
	]);
}
